import React from "react";
import ExploreLayout2 from "./ExploreLayout2";
import { styled } from "@mui/system";
import { ReactComponent as RR } from "../../assets/images/books_4-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_4-SP.svg";
import { ReactComponent as FF } from "../../assets/images/books_4-FF.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
  color: "var(--bas-0-body, #202020)",
  fontFamily: "Roboto",
  fontStyle: "normal",
  lineHeight: "normal",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade4EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Authentic Spanish";
  const title3 = "Futuristic Finds";

  return (
    <ExploreLayout2
      image1={RR}
      image2={SP}
      image3={FF}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Malala Yousafzai</strong> • Maria Isabel Sánchez Vegara • 4.9,
          LG • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Africa Is Not a Country</strong> • Margy Burns Knight • 5.3,
          LG • <strong>NF</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Martina una cucarachita muy linda: Un cuento cubano</strong> •
          Carmen Agra Deedy • 4.3, LG • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>Los deseos de Carmela</strong> • Matt de la Peña • 4.1, LG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Set the stage for inspiration and innovation, opening kids’ minds to
          question “what if” and imagine all that is possible.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Hello Ruby: Adventures in Coding</strong> • Linda Liukas •
          4.4, LG • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Out of Remote Control</strong> • Ada Hopper • 3.6, LG
        </AdditionalParagraph>
      </div>
    </ExploreLayout2>
  );
};

export default Grade4EnUS;
