import React from "react";
import { styled } from "@mui/system";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  padding:
    "var(--std-padding-lg) var(--std-padding-xs) var(--std-padding-lg) 0",
});

const StatList = styled("ul")({
  listStyle: "none",
  padding:
    "var(--std-padding-lg) var(--std-padding-sm) var(--std-padding-md) var(--std-padding-sm)",
  width: "80%",
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "14px",
  border: "1px solid #E5E5E5",
  gap: "32px",
  height: "100%",
  margin: 0,
});

const StatItem = styled("li")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StatLabel = styled("span")({
  textAlign: "center",
  fontSize: "16px",
  fontWeight: "bolder",
  marginBottom: "5px",
  color: "var(--black)",
});

const StatValue = styled("span")({
  color: "var(--black)",
  textAlign: "center",
  fontSize: "var(--std-font-xs)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const HighlightedStatValue = styled(StatValue)({
  color: "var(--black)",
  textAlign: "center",
  fontSize: "28px",
  fontWeight: 300,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const DetailedStatValue = styled(StatValue)({
  color: "var(--black)",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 400,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

interface YourTopBookStatsProps {
  studentsCount: number;
  booksRead: number;
  nonfictionPercent: number;
  wordsReadAvg: number;
  atosLevelAvg: number;
  topAuthors: string[];
}

const YourTopBookStats: React.FC<YourTopBookStatsProps> = ({
  studentsCount,
  booksRead,
  nonfictionPercent,
  wordsReadAvg,
  atosLevelAvg,
  topAuthors,
}) => {
  const formatNumber = (num: number) => num.toLocaleString();

  return (
    <Container>
      <StatList>
        <StatItem>
          <StatLabel>Students</StatLabel>
          <HighlightedStatValue>{studentsCount}</HighlightedStatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Books Read</StatLabel>
          <HighlightedStatValue>
            {booksRead ? formatNumber(booksRead) : 0}
          </HighlightedStatValue>
          <StatValue>{nonfictionPercent}% Nonfiction</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Student Averages</StatLabel>
          <DetailedStatValue>
            <div>
              Words Read:{" "}
              {wordsReadAvg ? formatNumber(Math.round(wordsReadAvg)) : 0}
            </div>
            <div>ATOS Level: {atosLevelAvg ? atosLevelAvg.toFixed(1) : 0}</div>
          </DetailedStatValue>
        </StatItem>
        <StatItem>
          <StatLabel>Top Authors</StatLabel>
          <DetailedStatValue>
            {topAuthors.map((author, index) => (
              <div key={index}>{author}</div>
            ))}
          </DetailedStatValue>
        </StatItem>
      </StatList>
    </Container>
  );
};

export default YourTopBookStats;
