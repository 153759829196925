import React from "react";
import { styled } from "@mui/system";
import { ReactComponent as FictionNonfiction } from "../../assets/images/wkar_fiction_nonfiction.svg";
import { useTranslation, Trans } from "react-i18next";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ImageContainer = styled("div")({
  position: "relative",
});

const Value = styled("p")({
  color: "var(--white)",
  fontSize: "var(--std-font-2xlg)",
  fontWeight: 600,
  position: "absolute",
  zIndex: 1,
});

const ValueFiction = styled(Value)({
  left: "28%",
  top: "52%",
  transform: "translate(-50%, -50%)",
});

const ValueNonfiction = styled(Value)({
  right: "26%",
  top: "52%",
  transform: "translate(50%, -50%)",
});

const Subtitle = styled("p")({
  color: "#555",
  textAlign: "center",
  fontSize: "var(--std-font-lg)",
  fontWeight: 400,
});

const RedText = styled("span")({
  color: "var(--red)",
});

interface OverallMetricsFictionNonfictionProps {
  fictionPercentage: number;
  nonfictionPercentage: number;
}

const OverallMetricsFictionNonfiction: React.FC<
  OverallMetricsFictionNonfictionProps
> = ({ fictionPercentage, nonfictionPercentage }) => {
  const { t } = useTranslation();

  return (
    <Container
      aria-label={`${fictionPercentage}% Fiction, ${nonfictionPercentage}% Nonfiction`}
    >
      <ImageContainer aria-hidden>
        <ValueFiction>{fictionPercentage}%</ValueFiction>
        <ValueNonfiction>{nonfictionPercentage}%</ValueNonfiction>
        <FictionNonfiction />
      </ImageContainer>
      <Subtitle>
        <Trans
          i18nKey="TEXT_WKAR_OVERALL_METRICS_FICTION_NONFICTION.SUBTITLE"
          components={{ 1: <RedText /> }}
        />
      </Subtitle>
    </Container>
  );
};

export default OverallMetricsFictionNonfiction;
