import React, { useState } from "react";
import { styled } from "@mui/system";
import { ReactComponent as BookK2 } from "../../assets/images/book_k-2-beige.svg";
import { ReactComponent as Book35 } from "../../assets/images/book_3-5-beige.svg";
import { ReactComponent as Book68 } from "../../assets/images/book_6-8-beige.svg";
import { ReactComponent as Book912 } from "../../assets/images/book_9-12-beige.svg";
import { ReactComponent as BooksAndStars } from "../../assets/images/books_stars.svg";
import WKARContainer from "./WKARContainer";
import { RATableToggleButton } from "../lib/button";
import {
  GradeLevelDataResponse,
  GradeBandResponse,
  School,
} from "./StoriesWrapper";
import GradesTable from "./GradesTable";
import useCheckMobileScreen from "../Utils";

interface GradeBandSummaryProps {
  gradeBandData: GradeBandResponse;
  gradeLevelData: GradeLevelDataResponse | null;
  title: string;
  date: string;
  selectedSchool: School;
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--std-padding-lg) var(--std-padding-lg) var(--std-padding-lg);
`;

const Table = styled("table")`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 var(--std-margin-lg);
`;

const DataRow = styled("tr")`
  background: var(--beige);
`;

const TableHeader = styled("th")`
  padding: 0 var(--std-padding-md) 0 0;
  text-align: center;
  font-weight: bold;
  vertical-align: bottom;
`;

const TableCell = styled("td")`
  padding: var(--std-padding-lg);
  text-align: center;
`;

const RoundedTableCell = styled(TableCell)<{
  isFirst?: boolean;
  isLast?: boolean;
}>`
  border-radius: ${({ isFirst, isLast }) =>
    isFirst ? "40px 0 0 40px" : isLast ? "0 40px 40px 0" : "0"};
  font-weight: ${({ isFirst }) => (isFirst ? "700" : "inherit")};
  text-align: ${({ isFirst }) => (isFirst ? "left" : "center")};
  padding-left: ${({ isFirst }) => (isFirst ? "43px" : "0")};
`;

const StyledText = styled("p")`
  font-size: 28px;
  font-weight: 300;
  margin: 0;
`;

const StyledSubtitle = styled("p")`
  font-size: var(--std-font-sm);
  font-weight: 400;
  margin: 0;
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  width: 100%;
  text-align: right;
  margin-right: var(--std-margin-lg);
`;

const Title = styled("h2")`
  font-size: var(--std-font-2xlg);
  font-weight: 400;
  margin: 0;
`;

const SectionTitle = styled(Title)`
  align-self: flex-end;
  justify-self: flex-end;
  color: var(--tablegrey);
`;

const GradeTitle = styled(Title)`
  color: var(--white);
`;

const DateText = styled("p")`
  color: var(--grey);
  font-size: var(--std-font-sm);
  font-weight: 400;
  margin: 0;
`;

const GradeBandLabel = styled("p")`
  color: var(--red);
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  margin: var(--std-margin-xs) 0 0 0;
`;

const GradeSubtitle = styled("p")`
  color: var(--white);
  font-size: var(--std-font-xs);
  font-weight: 400;
  margin: 0;
  text-align: center;
  padding: var(--std-padding-xxs) var(--std-padding-sm) 0 var(--std-padding-sm);
`;

const GradeContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const bookIcons: { [key: string]: JSX.Element } = {
  "K-2": <BookK2 aria-hidden />,
  "3-5": <Book35 aria-hidden />,
  "6-8": <Book68 aria-hidden />,
  "9-12": <Book912 aria-hidden />,
};

const TableHeaderRow: React.FC<{ gradeBandKeys: string[] }> = ({
  gradeBandKeys,
}) => (
  <tr>
    <TableHeader></TableHeader>
    {gradeBandKeys.map((key) => (
      <TableHeader key={key}>
        {bookIcons[key]}
        <GradeBandLabel>{key}</GradeBandLabel>
      </TableHeader>
    ))}
  </tr>
);

const DataRowComponent: React.FC<{
  gradeBandKeys: string[];
  gradeBandData: GradeBandResponse;
  isDistrict: boolean;
}> = ({ gradeBandKeys, gradeBandData, isDistrict }) => (
  <>
    <DataRow>
      <RoundedTableCell isFirst>Students</RoundedTableCell>
      {gradeBandKeys.map((key, index) => (
        <RoundedTableCell key={key} isLast={index === gradeBandKeys.length - 1}>
          <StyledText>
            {gradeBandData[key].students_count?.toLocaleString()}
          </StyledText>
          {isDistrict && (
            <StyledSubtitle>
              in {gradeBandData[key].schools_count?.toLocaleString()}{" "}
              {gradeBandData[key].schools_count === 1 ? "school" : "schools"}
            </StyledSubtitle>
          )}
        </RoundedTableCell>
      ))}
    </DataRow>
    <DataRow>
      <RoundedTableCell isFirst>Books Read</RoundedTableCell>
      {gradeBandKeys.map((key, index) => (
        <RoundedTableCell key={key} isLast={index === gradeBandKeys.length - 1}>
          <StyledText>
            {gradeBandData[key].books_read_count?.toLocaleString()}
          </StyledText>
        </RoundedTableCell>
      ))}
    </DataRow>
    <DataRow>
      <RoundedTableCell isFirst>% Nonfiction</RoundedTableCell>
      {gradeBandKeys.map((key, index) => (
        <RoundedTableCell key={key} isLast={index === gradeBandKeys.length - 1}>
          <StyledText>{gradeBandData[key].nonfiction_percent}%</StyledText>
        </RoundedTableCell>
      ))}
    </DataRow>
  </>
);

const GradeBandSummary: React.FC<GradeBandSummaryProps> = ({
  gradeBandData,
  gradeLevelData,
  title,
  date,
  selectedSchool,
}) => {
  const [showTable, setShowTable] = useState(false);
  const gradeBandKeys = Object.keys(gradeBandData);
  const isDistrict = selectedSchool.id.includes("_district");
  const summaryText = isDistrict ? "District Summary" : "School Summary";
  const subtitleText = isDistrict
    ? `All Schools in ${selectedSchool.name}`
    : selectedSchool.name;

  const toggleTable = () => {
    setShowTable((prevShowTable) => !prevShowTable);
  };

  let isMobileScreen: boolean = useCheckMobileScreen();

  const beigeBarContent = (
    <>
      <BooksAndStars
        aria-hidden
        style={{
          height: "160px",
          marginLeft: "-30px",
          display: `${isMobileScreen ? "none" : "block"}`,
        }}
      />
      <TextContainer>
        <DateText aria-label={`Date: ${date}`}>{date}</DateText>
        <SectionTitle aria-label={`Title: ${title}`}>{title}</SectionTitle>
      </TextContainer>
    </>
  );
  const redBarContent = (
    <GradeContainer>
      <GradeTitle>{summaryText}</GradeTitle>
      <GradeSubtitle>{subtitleText}</GradeSubtitle>
    </GradeContainer>
  );

  return (
    <WKARContainer
      showDefinitions={false}
      beigeBarWidth="75%"
      beigeBarContent={beigeBarContent}
      redBarContent={redBarContent}
    >
      {isMobileScreen ? (
        <MobileGradeBandSummary
          gradeBandData={gradeBandData}
          selectedSchool={selectedSchool}
        />
      ) : (
        <Container>
          <Table>
            <thead>
              <TableHeaderRow gradeBandKeys={gradeBandKeys} />
            </thead>
            <tbody>
              <DataRowComponent
                gradeBandKeys={gradeBandKeys}
                gradeBandData={gradeBandData}
                isDistrict={isDistrict}
              />
            </tbody>
          </Table>
          {gradeLevelData && gradeLevelData.grades && (
            <>
              <RATableToggleButton
                onClick={toggleTable}
                aria-expanded={showTable}
              >
                {showTable ? "Hide Grades Table" : "Show Grades Table"}
              </RATableToggleButton>
              {showTable && (
                <GradesTable
                  data={gradeLevelData}
                  grades={selectedSchool?.grades}
                />
              )}
            </>
          )}
        </Container>
      )}
    </WKARContainer>
  );
};

export default GradeBandSummary;

const MobileGradeBandSummary: React.FC<{
  gradeBandData: GradeBandResponse;
  selectedSchool: School;
}> = ({ gradeBandData, selectedSchool }) => {
  const gradeBandKeys = Object.keys(gradeBandData);

  const GradeBandContainer = styled("div")({
    marginBottom: "var(--margin-lg)",
    width: "100%",
  });

  const GradeBandHeader = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "var(--margin-md)",
  });

  return (
    <Container>
      {gradeBandKeys.map((key) => (
        <GradeBandContainer key={key}>
          <GradeBandHeader>
            {bookIcons[key]}
            <GradeBandLabel>{key}</GradeBandLabel>
          </GradeBandHeader>
          <Table>
            <tbody>
              <DataRow>
                <RoundedTableCell isFirst>Students</RoundedTableCell>
                <RoundedTableCell isLast>
                  <StyledText>
                    {gradeBandData[key].students_count?.toLocaleString()}
                  </StyledText>
                </RoundedTableCell>
              </DataRow>
              <DataRow>
                <RoundedTableCell isFirst>Books Read</RoundedTableCell>
                <RoundedTableCell isLast>
                  <StyledText>
                    {gradeBandData[key].books_read_count?.toLocaleString()}
                  </StyledText>
                </RoundedTableCell>
              </DataRow>
              <DataRow>
                <RoundedTableCell isFirst>% Nonfiction</RoundedTableCell>
                <RoundedTableCell isLast>
                  <StyledText>
                    {gradeBandData[key].nonfiction_percent?.toLocaleString()}%
                  </StyledText>
                </RoundedTableCell>
              </DataRow>
            </tbody>
          </Table>
        </GradeBandContainer>
      ))}
    </Container>
  );
};
