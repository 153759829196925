import React from "react";
import ExploreLayout1 from "./ExploreLayout1";
import { styled } from "@mui/system";
import { ReactComponent as SP } from "../../assets/images/books_3-SP.svg";
import { ReactComponent as RR } from "../../assets/images/books_3-RR.svg";
import { ReactComponent as GN } from "../../assets/images/books_3-GN.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade3EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Popular Graphic Novels";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout1
      image1={RR}
      image2={GN}
      image3={SP}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Seeds of Change: Planting a Path to Peace</strong> • Jen
          Cullerton Johnson • 4.8, LG • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>The Peace Bell</strong> • Margi Preus • 4.1, LG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Graphic novels illustrate a book’s action in a unique way that may
          capture kids’ attention where other books have not.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Cat Kid Comic Club</strong> • Dav Pilkey • 2.9, MG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>I Am Jackie Robinson</strong> • Brad Meltzer • 3.4, LG •{" "}
          <strong>NF</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Primer día en las uvas</strong> • L King Pérez • 3.8, LG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>Mi papi tiene una moto</strong> • Isabel Quintero • 3.9, LG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout1>
  );
};

export default Grade3EnUS;
