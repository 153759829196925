import { ReactComponent as STEM } from "../../assets/images/books_PreK-STEM.svg";
import { ReactComponent as RR } from "../../assets/images/books_PreK-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_PreK-SP.svg";
import { styled } from "@mui/system";
import ExploreLayout3 from "./ExploreLayout3";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const GradePreKEnUS = () => {
  const title1 = "Popular STEM Books";
  const title2 = "Recognizing Resilience";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout3
      image1={RR}
      image2={SP}
      image3={STEM}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Consider guiding students to science, technology, engineering, and
          math (STEM) topics, which may help spark their natural curiosity and
          echo individual interests.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Hoot, Owl!</strong> • Shelby Alinsky • 0.7, LG •{" "}
          <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Blackout</strong> • John Rocco • 1.0, LG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Julián at the Wedding</strong> • Jessica Love • 0.8, LG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Don't Worry Bear</strong> • Greg Foley • 1.6, LG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Mi día de la A a la Z</strong> • Isabel F. Campoy • 1.0, LG •
          <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>En el museo (libro bilingüe)</strong> • Isabel Muñoz • 1.1, LG
          • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout3>
  );
};

export default GradePreKEnUS;
