import React from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReportCover from "../../assets/images/report_cover.png";
import { HelpOutlineRounded } from "@mui/icons-material";
import { HtmlTooltip } from "../TitleTooltip";

// note: The text in this tooltip will need to be updated whenever the next WKAR report comes out
// (or we need to figure out a clever way of updating it automatically)

const Container = styled("div")({
  display: "flex",
  margin: "var(--std-margin-2xl) var(--std-margin-lg)",
});

const Image = styled(Box)({
  width: "53px",
  transform: "rotate(-8.488deg)",
});

const TextAndLinkContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "0 var(--std-margin-xs)",
});

const StyledLink = styled(Link)({
  color: "var(--linkblue)",
  fontSize: "var(--std-font-sm)",
  fontStyle: "italic",
  fontWeight: 600,
  textDecoration: "none", // Ensure the link has no underline
});

const StyledParagraph = styled("p")({
  color: "var(--tablegrey)",
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "var(--std-margin-xs) 0",
});

const TextAndIconContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const Heading = styled("h1")({
  fontSize: "var(--std-font-xlg)",
  fontWeight: 400,
  margin: 0,
});

const ListItem = styled("li")({
  fontSize: "var(--std-font-md)",
  fontWeight: 400,
});

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "var(--std-margin-lg) 0 0 0",
});

const LastYearsReportLink = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box
        component="img"
        style={{
          width: "50px",
          height: "56px",
          transform: "rotate(-8.488deg)",
        }}
        alt={t("TEXT_WKAR_LAST_YEARS_REPORT_LINK.COVER_ALT")}
        src={ReportCover}
      />
      <TextAndLinkContainer>
        <StyledLink
          to="https://www.renaissance.com/resources/what-kids-are-reading/"
          target="_blank"
          data-cy="last-years-report-link"
        >
          {t("TEXT_WKAR_LAST_YEARS_REPORT_LINK.DOWNLOAD_REPORT")}
        </StyledLink>
        <TextAndIconContainer>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Heading>
                  How are these shareable views different from the national
                  report?
                </Heading>
                <ul>
                  <ListItem>
                    This is specific to your schools and grade levels
                  </ListItem>
                  <ListItem>
                    You can see all your current reading statistics for the 2024
                    calendar year now
                  </ListItem>
                  <ListItem>
                    You will also be able to view a 2024-2025 school year
                    version of this report at the end of the school year
                  </ListItem>
                </ul>
                <Paragraph>
                  To see your <strong>state</strong> reading data, check out{" "}
                  <a
                    href="https://renaissance.com/resources/what-kids-are-reading/"
                    target="_blank"
                  >
                    renaissance.com/resources/what-kids-are-reading/
                  </a>
                </Paragraph>
              </React.Fragment>
            }
          >
            <HelpOutlineRounded
              sx={{
                color: "var(--black)",
                "&:hover": {
                  color: "var(--blue)",
                },
                fontSize: "var(--std-font-lg)",
                marginRight: "var(--std-margin-xxs)",
              }}
              tabIndex={0}
            />
          </HtmlTooltip>
          <StyledParagraph>
            {t("TEXT_WKAR_LAST_YEARS_REPORT_LINK.SHAREABLE_STORY")}
          </StyledParagraph>
        </TextAndIconContainer>
      </TextAndLinkContainer>
    </Container>
  );
};

export default LastYearsReportLink;
