import React from "react";
import { styled } from "@mui/material/styles";

const StyledDefinition = styled("p")({
  color: "var(--tablegrey)",
  fontSize: "9px",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "var(--std-margin-xs) var(--std-margin-lg)",
});

const Definitions = ({ showSpanish = true }: { showSpanish?: boolean }) => (
  <StyledDefinition>
    <strong>NF</strong> = Nonfiction &nbsp;&nbsp;&nbsp;
    {showSpanish && (
      <>
        <strong>SP</strong> = Available in Spanish
      </>
    )}
  </StyledDefinition>
);

export default Definitions;
