import React from "react";
import { styled } from "@mui/system";
import useCheckMobileScreen from "../Utils";
import Definitions from "./Definitions";

const ReadingContainer = styled("div")({
  width: "100%",
  maxWidth: "1031px",
  flexShrink: 0,
  borderRadius: "20px",
  background: "var(--white)",
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div")(
  ({ isMobileScreen }: { isMobileScreen: boolean }) => ({
    display: "flex",
    flexDirection: isMobileScreen ? "column" : "row",
    alignItems: isMobileScreen ? "center" : "flex-start",
  })
);

const BeigeBar = styled("div")<{ width: string; isMobileScreen: boolean }>(
  ({ width, isMobileScreen }) => ({
    width: isMobileScreen ? "100%" : width,
    borderRadius: isMobileScreen ? "20px 20px 0 0" : "20px 0 0 0",
    height: "77.589px",
    flexShrink: 0,
    background: "var(--beige)",
    display: "flex",
    justifyContent: "space-between",
  })
);

const RedBar = styled("div")(
  ({ isMobileScreen }: { isMobileScreen: boolean }) => ({
    display: "flex",
    width: isMobileScreen ? "100%" : "25%",
    borderRadius: isMobileScreen ? "0" : "0 20px 0 0",
    height: "77.589px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2.4px",
    flexShrink: 0,
    backgroundColor: "var(--red)",
  })
);

const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
});

interface WKARContainerProps {
  beigeBarWidth: string;
  beigeBarContent: React.ReactNode;
  showDefinitions?: boolean;
  redBarContent?: React.ReactNode;
  isTopBooks?: boolean;
  children?: React.ReactNode;
}

const WKARContainer: React.FC<WKARContainerProps> = ({
  beigeBarWidth,
  beigeBarContent,
  showDefinitions = true,
  redBarContent,
  isTopBooks = false,
  children,
}) => {
  const isFullWidth = beigeBarWidth === "100%";
  let isMobileScreen: boolean = useCheckMobileScreen();

  return (
    <ReadingContainer>
      <Header isMobileScreen={isMobileScreen}>
        <BeigeBar
          isMobileScreen={isMobileScreen}
          width={beigeBarWidth}
          data-cy="beige-bar"
        >
          {beigeBarContent}
        </BeigeBar>
        {!isFullWidth && (
          <RedBar isMobileScreen={isMobileScreen} data-cy="red-bar">
            {redBarContent}
          </RedBar>
        )}
      </Header>
      <Content data-cy="content">
        {children}
        {showDefinitions && <Definitions showSpanish={!isTopBooks} />}
      </Content>
    </ReadingContainer>
  );
};

export default WKARContainer;
