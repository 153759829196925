import React, { useContext } from "react";
import { styled } from "@mui/system";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fadeInSlideUp } from "../../AppStyles";
import { ReactComponent as RenNext } from "../../assets/images/ren_next.svg";
import { ReactComponent as RenNextBeta } from "../../assets/images/ren_next_beta_for_leaders.svg";
import { AuthCookieContext } from "../AuthCookieContext";
import TeacherJourneyToggle from "./TeacherJourneyToggle";
import OptInOptOut from "./OptInOptOut";

const RenaissanceNextPane = ({
  hasTeacherJourneyLaunch,
  isWaffle,
}: {
  hasTeacherJourneyLaunch: boolean;
  isWaffle?: boolean | undefined;
}) => {
  interface PaneProps {
    isWaffle: boolean | undefined;
  }
  const Pane = styled("div")<PaneProps>`
    width: 92%;
    background: rgba(216, 216, 216, 0.33);
    margin: var(--std-margin-md) 0px 0px -17px;
    border-left: 6px solid var(--red);
    padding: var(--std-padding-md);
    animation: ${fadeInSlideUp} 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    ${(props) =>
      props.isWaffle &&
      `
    background: white;
    width: 15rem;
    margin: 0;
    border-left: none;
  `}
  `;
  const authCookieContext = useContext(AuthCookieContext);
  const { frontendOptInOptOut, frontendRenNextBranding } = useFlags();
  return (
    <Pane isWaffle={isWaffle}>
      {frontendRenNextBranding ? (
        <RenNext
          aria-label="Renaissance Next for Leaders logo"
          style={{ width: "173px" }}
        />
      ) : (
        <RenNextBeta
          aria-label="Renaissance Next for Leaders Beta logo"
          style={{ width: "200px" }}
        />
      )}
      {authCookieContext?.role !== "anonymous" && frontendOptInOptOut ? (
        <OptInOptOut />
      ) : null}
      {hasTeacherJourneyLaunch ? <TeacherJourneyToggle /> : null}
    </Pane>
  );
};

export default RenaissanceNextPane;
