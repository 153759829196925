import React from "react";
import { styled } from "@mui/system";
import BookContainer from "./BookContainer";
import { Book } from "./StoriesWrapper";
import { ReactComponent as ARLogo } from "../../assets/images/AR_logo-WKAR.svg";

interface ARBooksProps {
  books: Book[];
}

const Container = styled("div")({
  borderRadius: "14px",
  border: "1px solid #E5E5E5", // not a current grey in our library
  padding: "var(--std-padding-xs) var(--std-padding-sm)",
  display: "flex",
  flexDirection: "column",
});

const ARBooks: React.FC<ARBooksProps> = ({ books }) => {
  return (
    <Container>
      <h4
        role="heading"
        aria-level={4}
        aria-label="Accelerated Reader"
        style={{ margin: 0 }}
      >
        <ARLogo aria-hidden />
      </h4>
      <BookContainer books={books} />
    </Container>
  );
};

export default ARBooks;
