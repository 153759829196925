import { ReactComponent as HLC } from "../../assets/images/books_11-HLC.svg";
import { ReactComponent as RR } from "../../assets/images/books_11-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_11-SP.svg";
import { styled } from "@mui/system";
import ExploreLayout3 from "./ExploreLayout3";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade11EnUS = () => {
  const title1 = "High-Level Contemporary";
  const title2 = "Recognizing Resilience";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout3
      image1={RR}
      image2={SP}
      image3={HLC}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          High-level books written by contemporary authors may bridge the gap
          between self-selected titles and curricular reading.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Mountains Beyond Mountains</strong> • Tracy Kidder • 8.6, MG+
          • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>How to Survive a Robot Uprising</strong> • Daniel H. Wilson •
          9.2, UG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Fred Korematsu Speaks Up</strong> • Laura Atkins • 6.1, MG •{" "}
          <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Change the Game</strong> • Colin Kaepernick • 3.1 UG •{" "}
          <strong>NF</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Del amor y otros demonios</strong> • Gabriel García Márquez •
          8.0, UG • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>La corza blanca</strong> • Gustavo Adolfo Bécquer • 5.5, UG •
          <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout3>
  );
};

export default Grade11EnUS;
