import React from "react";
import { styled } from "@mui/system";
import { ReactComponent as BookK2 } from "../../assets/images/book_k-2.svg";
import WKARContainer from "./WKARContainer";
import {
  Grade,
  GradeLevelDataResponse,
  GradeLevelData,
  School,
} from "./StoriesWrapper";
import ARBooks from "./ARBooks";
import MyONBooks from "./MyONBooks";
import YourTopBookStats from "./YourTopBookStats";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  "@media (max-width: 800px)": {
    flexDirection: "column",
  },
});

const BookSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxWidth: "72%",
  minWidth: "72%",
  padding:
    "var(--std-padding-lg) var(--std-padding-sm) var(--std-padding-lg) var(--std-padding-lg)",
  gap: "var(--std-padding-lg)",
  "@media (max-width: 800px)": {
    order: 2,
    maxWidth: "90%",
    minWidth: "90%",
  },
});

const StatsSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  minWidth: "25%",
  maxWidth: "25%",
  "@media (max-width: 800px)": {
    order: 1,
    minWidth: "90%",
    maxWidth: "90%",
  },
});

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "right",
  width: "60%",
  marginRight: "var(--std-margin-lg)",
});

const Title = styled("h3")({
  fontSize: "var(--std-font-2xlg)",
  fontWeight: 400,
  margin: 0,
});

const SectionTitle = styled(Title)({
  alignSelf: "flex-end",
  justifySelf: "flex-end",
  color: "var(--tablegrey)",
});

const GradeTitle = styled(Title)({
  color: "var(--white)",
});

const GradeSubtitle = styled("p")`
  color: var(--white);
  font-size: var(--std-font-xs);
  font-weight: 400;
  margin: 0;
  text-align: center;
  padding: var(--std-padding-xxs) var(--std-padding-sm) 0 var(--std-padding-sm);
`;

const GradeContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DateText = styled("p")({
  color: "var(--grey)",
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: 0,
});

const StyledH3 = styled("h3")`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  margin: var(--std-padding-4xl) 0;
`;

export const YourTopBooks = ({
  title,
  date,
  selectedGrade,
  gradeLevelData,
  selectedSchool,
}: {
  title: string;
  date: string;
  selectedGrade: Grade;
  gradeLevelData: GradeLevelDataResponse | null;
  selectedSchool: School;
}) => {
  if (
    !gradeLevelData ||
    !selectedGrade ||
    !gradeLevelData.grades ||
    !selectedSchool
  ) {
    return <div>Loading...</div>;
  }
  const isDistrict = selectedSchool.id.includes("_district");
  const subtitleText = isDistrict
    ? `All Schools in ${selectedSchool.name}`
    : selectedSchool.name;

  const gradeData = gradeLevelData.grades[selectedGrade.grade_level];

  if (!gradeData) {
    return <div>No data available for the selected grade.</div>;
  }

  const arBookData = gradeData.ar_books;
  const myonBookData = gradeData.myon_books;

  const arBooksToShow =
    arBookData.length > 0
      ? arBookData.slice(0, myonBookData.length > 0 ? 5 : 10)
      : [];
  const myonBooksToShow =
    myonBookData.length > 0
      ? myonBookData.slice(0, arBookData.length > 0 ? 5 : 10)
      : [];

  const beigeBarContent = (
    <>
      <BookK2
        style={{ width: "40%", justifyContent: "flex-end" }}
        aria-label="Book K-2"
        aria-hidden="true"
      />
      <TextContainer>
        <DateText aria-label={`Date: ${date}`}>{date}</DateText>
        <SectionTitle aria-label={`Title: ${title}`}>{title}</SectionTitle>
      </TextContainer>
    </>
  );
  const redBarContent = (
    <GradeContainer>
      <GradeTitle style={{ color: "var(--white)" }}>
        {selectedGrade.grade_level_long}
      </GradeTitle>
      <GradeSubtitle>{subtitleText}</GradeSubtitle>
    </GradeContainer>
  );

  const NoBooks = () => (
    <StyledH3>
      Your {selectedGrade.grade_level_long} students have not taken an AR quiz
      or opened a myON digital book yet.{" "}
    </StyledH3>
  );
  return (
    <WKARContainer
      beigeBarWidth="75%"
      beigeBarContent={beigeBarContent}
      redBarContent={redBarContent}
      isTopBooks
    >
      {arBooksToShow?.length || myonBooksToShow?.length ? (
        <Container>
          <BookSection>
            {arBooksToShow?.length > 0 && <ARBooks books={arBooksToShow} />}
            {myonBooksToShow?.length > 0 && (
              <MyONBooks books={myonBooksToShow} />
            )}
          </BookSection>
          <StatsSection>
            <YourTopBookStats
              studentsCount={gradeData.students_count}
              booksRead={gradeData.books_read}
              nonfictionPercent={gradeData.nonfiction_percent}
              wordsReadAvg={gradeData.words_read_avg}
              atosLevelAvg={gradeData.atos_level_avg}
              topAuthors={gradeData.top_authors}
            />
          </StatsSection>
        </Container>
      ) : (
        <NoBooks />
      )}
    </WKARContainer>
  );
};
