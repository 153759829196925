import React from "react";
import { styled } from "@mui/system";
import useCheckMobileScreen from "../Utils";

const Container = styled("div")<{ isMobile: boolean }>(({ isMobile }) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "space-between",
  padding:
    "var(--std-padding-lg) var(--std-padding-lg) 0 var(--std-padding-lg)",
  height: "92%",
}));

const RightContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 2,
  padding: "var(--std-padding-md)",
});

const Column = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const Row = styled("div")({
  display: "flex",
  flexDirection: "row",
});

const LeftColumn = styled(Column)({
  border: "1px solid #E5E5E5",
  padding: "0 var(--std-padding-sm) 0 var(--std-padding-lg)",
  marginBottom: "var(--std-margin-sm)",
});

const Title = styled("h4")({
  color: "var(--red)",
  fontSize: "var(--std-font-2xlg)",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  margin: "var(--std-margin-md) 0 0 0",
});

const ParagraphContainer = styled("p")({
  color: "var(--tablegrey)",
});

const ExploreLayout3 = ({
  image1: Image1,
  image2: Image2,
  image3: Image3,
  title1,
  title2,
  title3,
  children,
}: {
  image1: React.FC;
  image2: React.FC;
  image3: React.FC;
  title1: string;
  title2: string;
  title3: string;
  children: React.ReactNode[];
}) => {
  const isMobileScreen = useCheckMobileScreen();
  return (
    <Container isMobile={isMobileScreen}>
      <LeftColumn>
        <Title>{title1}</Title>
        <ParagraphContainer>{children[0]}</ParagraphContainer>
        <Image3 aria-hidden />
      </LeftColumn>
      <RightContainer>
        <Row>
          <Image1 aria-hidden />
          <Column>
            <Title>{title2}</Title>
            <ParagraphContainer>{children[1]}</ParagraphContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <Title>{title3}</Title>
            <ParagraphContainer>{children[2]}</ParagraphContainer>
          </Column>
          <Image2 aria-hidden />
        </Row>
      </RightContainer>
    </Container>
  );
};

export default ExploreLayout3;
