import React from "react";
import ExploreLayout1 from "./ExploreLayout1";
import { styled } from "@mui/system";
import { ReactComponent as SP } from "../../assets/images/books_12-SP.svg";
import { ReactComponent as RR } from "../../assets/images/books_12-RR.svg";
import { ReactComponent as FF } from "../../assets/images/books_12-FF.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const TwelfthGradeEnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Futuristic Finds";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout1
      image1={RR}
      image2={FF}
      image3={SP}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>The Girl Who Escaped ISIS: This Is My Story</strong> • Farida
          Khalaf • 5.5, UG • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Beneath the Wide Silk Sky</strong> • Emily Inouye Huey • 4.4,
          UG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Set the stage for inspiration and innovation, opening kids’ minds to
          question “what if” and imagine all that is possible.
        </Paragraph>
        <AdditionalParagraph>
          <strong>2001: A Space Odyssey</strong> • Arthur C. Clarke • 9.0, UG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Robopocalypse</strong> • Daniel H. Wilson • 4.9, UG
        </AdditionalParagraph>
      </div>

      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Crónica de una muerte anunciada</strong> • Gabriel García
          Márquez • 8.1, UG • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>Don Quijote de la Mancha</strong> • Miguel de Cervantes
          Saavedra • 13.2, UG • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout1>
  );
};

export default TwelfthGradeEnUS;
