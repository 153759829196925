import { ReactComponent as GN } from "../../assets/images/books_5-GN.svg";
import { ReactComponent as RR } from "../../assets/images/books_5-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_5-SP.svg";
import { styled } from "@mui/system";
import ExploreLayout3 from "./ExploreLayout3";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade5EnUS = () => {
  const title1 = "Popular Graphic Novels";
  const title2 = "Recognizing Resilience";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout3
      image1={RR}
      image2={SP}
      image3={GN}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Graphic novels illustrate a book’s action in a unique way that may
          capture kids’ attention where other books have not.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Wonderstruck</strong> • Brian Selznick • 5.4, MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>When Stars Are Scattered</strong> • Victoria Jamieson • 3.7,
          MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Going Places: Victor Hugo Green and His Glorious Book</strong>{" "}
          • Tonya Bolden • 6.1, LG • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Paradise on Fire</strong> • Jewell Parker Rhodes • 3.3, MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Cuadros de familia (libro bilingüe)</strong> • Carmen Lomas
          Garza • 4.3, LG • NF • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>Martí y sus versos por la libertad (libro bilingüe)</strong> •
          Emma Otheguy • 4.9, LG • <strong>NF</strong> • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout3>
  );
};

export default Grade5EnUS;
