import React, { useMemo } from "react";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { YourTopBooks } from "./YourTopBooks";
import LastYearsReportLink from "./LastYearsReportLink";
import OverallMetrics from "./OverallMetrics";
import SchoolDropdown from "./SchoolDropdown";
import {
  CustomerMetadata,
  Grade,
  GradeBandResponse,
  GradeLevelDataResponse,
  OverallStats,
  School,
} from "./StoriesWrapper";
import GradeDropdown from "./GradeDropdown";
import { Explore } from "./Explore";
import GradeBandSummary from "./GradeBandSummary";
import useCheckMobileScreen from "../Utils";
import { DownloadWKARButton } from "./DownloadWKARButton";
import { Box } from "@mui/material";

// TODO: Waiting on the real image
const NoDataImage = require("../../assets/images/wkar_no_data.gif");

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "0 0 0 var(--std-padding-xlg)",
  width: "100%",
});

const FlexContainer = styled("div")<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

const YourTopBooksContainer = styled("div")({
  margin: "var(--std-margin-lg) 0",
});

const GradeBandSummaryContainer = styled("div")({
  margin: "var(--std-margin-lg) 0",
});

const TitleContainer = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const Heading = styled("h1")({
    color: "var(--red)",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    margin: 0,
  });

  const Description = styled("p")({
    color: "var(--tablegrey)",
    fontSize: "var(--std-font-sm)",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "30px",
    margin: 0,
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Heading aria-label={title}>{title}</Heading>
      <Description aria-label={description}>{description}</Description>
    </div>
  );
};

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const LoadingBox = styled("div")`
  border-radius: 20px;
  background: linear-gradient(to right, #eee 8%, #fafafa 18%, #eee 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
`;

const LoadingBoxLarge = styled(LoadingBox)`
  width: 1024px;
  height: 576px;
  margin: var(--std-margin-lg) 0;
`;

const LoadingBoxSmall = styled(LoadingBox)`
  width: 1024px;
  height: 70px;
  margin: var(--std-margin-lg) 0;
`;

const NoDataMessage = styled("div")`
  width: 80%;
  height: 70px;
  padding: var(--std-margin-lg);
  font-size: var(--std-font-lg);
  text-align: center;
  background: var(--white);
  border-radius: 20px;
  margin: var(--std-margin-lg) 0;
`;

const LoadingOrNoData = React.memo(
  ({
    isLoading,
    size,
    children,
  }: {
    isLoading: boolean;
    size: "small" | "large";
    children: React.ReactNode;
  }) => {
    const { t } = useTranslation();
    const loadingBox = useMemo(() => {
      return size === "small" ? <LoadingBoxSmall /> : <LoadingBoxLarge />;
    }, [size]);

    if (isLoading) {
      return loadingBox;
    }

    return (
      <>
        {children || (
          <NoDataMessage>{t("TEXT_NO_DATA_AVAILABLE")}</NoDataMessage>
        )}
      </>
    );
  }
);

const ComingSoonContainer = styled("div")({
  width: "80%",
  padding: "var(--std-margin-lg)",
  textAlign: "center",
  background: "var(--white)",
  borderRadius: "20px",
  margin: "var(--std-margin-lg) ",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "var(--std-margin-md)",
});

const ComingSoonTitle = styled("h1")({
  color: "var(--red)",
  fontSize: "28px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal",
  margin: 0,
});

const ComingSoonDescription = styled("p")({
  fontSize: "var(--std-font-md)",
  color: "var(--tablegrey)",
  margin: 0,
  lineHeight: 1.5,
});

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ComingSoonMessage = ({
  headerText,
  descriptionText,
}: {
  headerText: string;
  descriptionText: string;
}) => {
  return (
    <ComingSoonContainer>
      {/* TODO:  We have an SVG version of this at wkar_no_data.svg, but I don't know how to make animated svgs work */}
      <Box
        component="img"
        aria-label="Story coming soon"
        aria-hidden="true"
        src={NoDataImage}
        sx={{ marginRight: "var(--std-margin-lg)" }}
      />
      <TextContainer>
        <ComingSoonTitle>{headerText}</ComingSoonTitle>
        <ComingSoonDescription>{descriptionText}</ComingSoonDescription>
      </TextContainer>
    </ComingSoonContainer>
  );
};
const Stories = ({
  customerMetadata,
  overallStats,
  selectedSchool,
  selectSchool,
  selectedGrade,
  selectGrade,
  gradeLevelData,
  gradeBandData,
  isLoadingCustomerMetadata,
  isLoadingOverallStats,
  isLoadingGradeLevelData,
  isLoadingGradeBandData,
}: {
  customerMetadata: CustomerMetadata | null;
  overallStats: OverallStats | null;
  selectedSchool: School | undefined;
  selectSchool: (school: School) => void;
  selectedGrade: Grade | undefined;
  selectGrade: (grade: Grade) => void;
  gradeLevelData: GradeLevelDataResponse | null;
  gradeBandData: GradeBandResponse | null;
  isLoadingCustomerMetadata: boolean;
  isLoadingOverallStats: boolean;
  isLoadingGradeLevelData: boolean;
  isLoadingGradeBandData: boolean;
}) => {
  const { t } = useTranslation();
  let isMobileScreen: boolean = useCheckMobileScreen();

  if (!customerMetadata && !isLoadingCustomerMetadata) {
    return (
      <ComingSoonMessage
        headerText={t("TEXT_WKAR_COMING_SOON")}
        descriptionText={t("TEXT_WKAR_COMING_SOON_DESCRIPTION")}
      />
    );
  }

  return (
    <Container>
      {customerMetadata && !isLoadingCustomerMetadata && (
        <TitleContainer
          title={t("TEXT_STORIES_TITLE")}
          description={t("TEXT_STORIES_DESCRIPTION")}
        />
      )}

      <LoadingOrNoData isLoading={isLoadingCustomerMetadata} size="small">
        {customerMetadata && selectedSchool && (
          <FlexContainer isMobile={isMobileScreen}>
            <SchoolDropdown
              customerMetadata={customerMetadata}
              selectedSchool={selectedSchool}
              selectSchool={selectSchool}
            />
            <DownloadWKARButton selectedSchool={selectedSchool} />
          </FlexContainer>
        )}
      </LoadingOrNoData>
      <LoadingOrNoData
        isLoading={isLoadingOverallStats || isLoadingCustomerMetadata}
        size="large"
      >
        {customerMetadata?.district?.products &&
          overallStats &&
          selectedSchool && (
            <OverallMetrics
              products={customerMetadata.district.products}
              school={selectedSchool}
              overallStats={overallStats}
            />
          )}
      </LoadingOrNoData>
      <LoadingOrNoData isLoading={isLoadingCustomerMetadata} size="small">
        {/* selected school and grade comes from customer metadata */}
        {selectedSchool && selectedGrade && (
          <FlexContainer isMobile={isMobileScreen}>
            <LastYearsReportLink />
            <GradeDropdown
              selectedSchool={selectedSchool}
              selectedGrade={selectedGrade}
              selectGrade={selectGrade}
            />
          </FlexContainer>
        )}
      </LoadingOrNoData>
      <LoadingOrNoData
        isLoading={isLoadingGradeLevelData || isLoadingCustomerMetadata}
        size="large"
      >
        {gradeLevelData && selectedGrade && selectedSchool && (
          <YourTopBooksContainer>
            <YourTopBooks
              title={t("TEXT_WYKAR_TITLE")}
              date="JANUARY - DECEMBER 2024"
              selectedGrade={selectedGrade}
              gradeLevelData={gradeLevelData}
              selectedSchool={selectedSchool}
            />
          </YourTopBooksContainer>
        )}
      </LoadingOrNoData>
      <LoadingOrNoData isLoading={isLoadingCustomerMetadata} size="large">
        {/* selected school and grade comes from customer metadata */}
        {selectedGrade && <Explore selectedGrade={selectedGrade} />}
      </LoadingOrNoData>
      <LoadingOrNoData
        isLoading={isLoadingGradeBandData || isLoadingCustomerMetadata}
        size="large"
      >
        {gradeBandData && selectedSchool && gradeLevelData && (
          <GradeBandSummaryContainer>
            <GradeBandSummary
              title={t("TEXT_WYKAR_TITLE")}
              date="JANUARY - DECEMBER 2024"
              selectedSchool={selectedSchool}
              gradeBandData={gradeBandData}
              gradeLevelData={gradeLevelData}
            />
          </GradeBandSummaryContainer>
        )}
      </LoadingOrNoData>
    </Container>
  );
};

export default Stories;
