import React from "react";
import { styled } from "@mui/system";
import { ReactComponent as WordCloud } from "../../assets/images/wkar_word_cloud.svg";
import { useTranslation } from "react-i18next";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Value = styled("p")({
  color: "var(--red)",
  fontSize: "var(--std-font-4xlg)",
  margin: "-30px 0",
  fontWeight: 400,
  zIndex: 1,
});

const Subtitle = styled("p")({
  color: "#555", // isn't one of the standard colors
  textAlign: "center",
  fontSize: "var(--std-font-lg)",
  fontWeight: 400,
});

interface OverallMetricsWordsReadProps {
  value: number;
}

const OverallMetricsWordsRead: React.FC<OverallMetricsWordsReadProps> = ({
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Container
      aria-label={`${value?.toLocaleString()} ${t(
        "TEXT_WKAR_OVERALL_METRICS_WORDS_READ.SUBTITLE"
      )}`}
    >
      <Value>{value?.toLocaleString()}</Value>
      <WordCloud aria-hidden />
      <Subtitle>{t("TEXT_WKAR_OVERALL_METRICS_WORDS_READ.SUBTITLE")}</Subtitle>
    </Container>
  );
};

export default OverallMetricsWordsRead;
