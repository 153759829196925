import * as React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as Logo } from "../assets/images/renaissance.svg";
import { ReactComponent as LogoRA } from "../assets/images/renaissance_analytics.svg";
import { ReactComponent as RLogo } from "../assets/images/R.svg";
import { useFlags } from "launchdarkly-react-client-sdk";

export function NavbarLogo({ isMobileScreen }: { isMobileScreen: boolean }) {
  const { frontendRenNextBranding } = useFlags();
  return (
    <Grid
      item
      sx={{
        width: 185,
        marginRight: "var(--std-margin-3xl)",
        marginTop: "var(--std-margin-md)",
        alignItem: "center",
      }}
    >
      {isMobileScreen ? (
        <RLogo
          aria-label={
            frontendRenNextBranding
              ? "Renaissance Next for Leaders"
              : "Rennaissance Analytics"
          }
          style={{ width: "40px" }}
        />
      ) : frontendRenNextBranding ? (
        <Logo aria-label="Renaissance Next for Leaders" />
      ) : (
        <LogoRA
          aria-label="Rennaissance Analytics"
          style={{ width: "120px" }}
        />
      )}
    </Grid>
  );
}
