import { ReactComponent as GN } from "../../assets/images/books_8-PB.svg";
import { ReactComponent as RR } from "../../assets/images/books_8-RR.svg";
import { ReactComponent as SP } from "../../assets/images/books_8-SP.svg";
import { styled } from "@mui/system";
import ExploreLayout3 from "./ExploreLayout3";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade8EnUS = () => {
  const title1 = "Popular Book Series";
  const title2 = "Recognizing Resilience";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout3
      image1={RR}
      image2={SP}
      image3={GN}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Guiding students in finding a book series may motivate middle school
          readers, as it’s often hard to resist the next installment in a series
          after being drawn in by the first satisfying read.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Wings of Fire Series</strong> • Tui T. Sutherland • 5.0–5.6,
          MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Spy School Series</strong> • Stuart Gibbs • 5.2–6.1, MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>
            How Do You Spell Unfair? MacNolia Cox and the National Spelling Bee
          </strong>{" "}
          • Carole Boston Weatherford • 5.1, MG • <strong>NF</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>Coyote Queen</strong> • Jessica Vitalis • 5.0, MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>No tiene que ser así: Una historia del barrio</strong> • Luis
          J. Rodríguez • 4.3, MG • <strong>NF</strong> • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>¿Qué es un soñador? (libro bilingüe)</strong> • Brenda Perez
          Mendoza • 4.9, MG+ • <strong>NF</strong> • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout3>
  );
};

export default Grade8EnUS;
