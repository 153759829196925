import React from "react";
import ExploreLayout1 from "./ExploreLayout1";
import { styled } from "@mui/system";
import { ReactComponent as SP } from "../../assets/images/books_9-SP.svg";
import { ReactComponent as RR } from "../../assets/images/books_9-RR.svg";
import { ReactComponent as FF } from "../../assets/images/books_9-FF.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade9EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Futuristic Finds";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout1
      image1={RR}
      image2={FF}
      image3={SP}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Treasure of the World</strong> • Tara Sullivan • 5.5, MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>When Clouds Touch Us</strong> • Thanhhà Lai • 5.4, MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Set the stage for inspiration and innovation, opening kids’ minds to
          question “what if” and imagine all that is possible.
        </Paragraph>
        <AdditionalParagraph>
          <strong>I, Robot</strong> • Isaac Asimov • 6.1, UG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>The Six</strong> • Mark Alpert • 5.7, UG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Iveliz lo explica todo</strong> • Andrea Beatriz Arango • 5.1,
          MG+ • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>Excelencia en las artes (libro bilingüe)</strong> • Brenda
          Perez Mendoza • 6.0, MG+ • <strong>NF</strong> • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout1>
  );
};

export default Grade9EnUS;
