import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";
import {
  Grade,
  GradeLevelData,
  GradeLevelDataResponse,
} from "./StoriesWrapper";

interface GradesTableProps {
  data: GradeLevelDataResponse;
  grades: Grade[];
}

type Order = "asc" | "desc";

interface HeadCell {
  id: keyof GradeLevelData | "grade" | "books_read_avg" | "grade_level_numeric";
  label: string;
  numeric: boolean;
}

const Title = styled("h4")({
  color: "var(--red)",
  fontSize: "var(--std-font-xlg)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "var(--std-margin-lg) 0  var(--std-margin-xxs) 0",
  alignSelf: "flex-start",
});

const Subtitle = styled("p")({
  color: "var(--tablegrey)",
  fontSize: "var(--std-font-md)",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  alignSelf: "flex-start",
  margin: "0 0 var(--std-margin-md) 0 ",
});

const TotalsRow = styled(TableRow)({
  backgroundColor: "#f0f0f0", // custom colour
  "& .MuiTableCell-root": {
    fontWeight: "bold",
    textAlign: "right",
    fontSize: "var(--std-font-md)",
    padding: "var(--std-padding-xs)",
  },
});

const StyledTableRow = styled(TableRow)({
  padding: "0",
  "& .MuiTableCell-root": {
    padding: "var(--std-padding-xxs)",
    textAlign: "right",
  },
});

const GradeCell = styled(TableCell)({
  fontSize: "var(--std-font-md)",
  color: "var(--black)",
});

const TableHeaderRow = styled(TableRow)({
  // styling the default material UI table to look like the mockup table
  backgroundColor: "#555", // custom colour
  "& .MuiTableCell-root": {
    color: "var(--white)",
    fontWeight: "normal",
    fontSize: "15px", // custom size
    padding:
      "var(--std-padding-sm) var(--std-padding-xs) var(--std-padding-xxs) 0",
    textAlign: "right",
    borderRight: "2px solid var(--white)",
    width: "158px",
    lineHeight: "normal",
    "&:hover": {
      backgroundColor: "#333",
      color: "var(--white)",
    },
    "& span": {
      color: "var(--white)",
      "&:hover": {
        color: "var(--white)",
      },
      "& svg": {
        color: "var(--white) !important",
        "&:hover": {
          color: "var(--white) !important",
        },
      },
    },
  },
});

const headCells: HeadCell[] = [
  { id: "grade_level_numeric", numeric: false, label: "Grade" },
  { id: "students_count", numeric: true, label: "Students" },
  { id: "books_read", numeric: true, label: "Books read" },
  {
    id: "books_read_avg",
    numeric: true,
    label: "Average books read per student",
  },
  {
    id: "words_read_avg",
    numeric: true,
    label: "Average words read per student",
  },
  { id: "atos_level_avg", numeric: true, label: "Average ATOS book level" },
];

const GradesTable: React.FC<GradesTableProps> = ({ data, grades }) => {
  // we need to pull out the grade_level_numeric from the grades array, and use that for the initial sort
  const initialData = Object.keys(data.grades)
    .map((grade: string) => {
      const gradeInfo = grades.find((g: Grade) => g.grade_level === grade);
      const gradeLevelNumeric = gradeInfo ? gradeInfo.grade_level_numeric : 0;
      return {
        grade,
        grade_level_numeric: gradeLevelNumeric,
        ...data.grades[grade],
        books_read_avg:
          data.grades[grade].books_read / data.grades[grade].students_count,
      };
    })
    .sort((a, b) => a.grade_level_numeric - b.grade_level_numeric);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<
    keyof GradeLevelData | "grade" | "books_read_avg" | "grade_level_numeric"
  >("grade_level_numeric");
  const [sortedData, setSortedData] = useState(initialData);

  const handleRequestSort = (
    property:
      | keyof GradeLevelData
      | "grade"
      | "books_read_avg"
      | "grade_level_numeric"
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    const sorted = [...initialData].sort((a, b) => {
      if (property === "grade") {
        return newOrder === "asc"
          ? a.grade_level_numeric - b.grade_level_numeric
          : b.grade_level_numeric - a.grade_level_numeric;
      } else {
        return newOrder === "asc"
          ? (a[property] as number) - (b[property] as number)
          : (b[property] as number) - (a[property] as number);
      }
    });

    setSortedData(sorted);
  };

  return (
    <>
      <Title>Students, Books, Words, and Average ATOS by Grade</Title>
      <Subtitle>Accelerated Reader and myON, 2024 calendar year</Subtitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableHeaderRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="right"
                  sortDirection={orderBy === headCell.id ? order : false}
                  data-cy={headCell.id}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <StyledTableRow key={row.grade} data-cy={row.grade}>
                <GradeCell align="left">{row.grade}</GradeCell>
                <TableCell align="right">
                  {row.students_count.toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  {row.books_read.toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  {row.books_read_avg.toFixed(1)}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(row.words_read_avg.toFixed(1)).toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  {row.atos_level_avg === 0
                    ? "-"
                    : row.atos_level_avg.toFixed(1)}
                </TableCell>
              </StyledTableRow>
            ))}
            <TotalsRow>
              <TableCell align="left">Totals</TableCell>
              <TableCell align="right">
                {data.totals.total_students.toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {data.totals.total_books.toLocaleString()}
              </TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
            </TotalsRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GradesTable;
