import React from "react";
import ExploreLayout1 from "./ExploreLayout1";
import { styled } from "@mui/system";
import { ReactComponent as SP } from "../../assets/images/books_6-SP.svg";
import { ReactComponent as RR } from "../../assets/images/books_6-RR.svg";
import { ReactComponent as PS } from "../../assets/images/books_6-PS.svg";

const Paragraph = styled("p")({
  fontSize: "var(--std-font-sm)",
  fontWeight: 400,
  margin: "0",
});

const AdditionalParagraph = styled(Paragraph)({
  margin: "var(--std-margin-md) 0",
});

const Grade6EnUS = () => {
  const title1 = "Recognizing Resilience";
  const title2 = "Popular Book Series";
  const title3 = "Authentic Spanish";

  return (
    <ExploreLayout1
      image1={RR}
      image2={PS}
      image3={SP}
      title1={title1}
      title2={title2}
      title3={title3}
    >
      <div>
        <Paragraph>
          Books allow us to recognize our shared humanity, helping readers to
          feel seen or to build empathy for the varied—and sometimes
          challenging, though often inspiring—journeys we all are traveling.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Finally Seen</strong> • Kelly Yang • 4.3, MG
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>The Many Masks of Andy Zhou</strong> • Jack Cheng • 4.4, MG
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Guiding students in finding a book series may motivate middle school
          readers, as it’s often hard to resist the next installment in a series
          after being drawn in by the first satisfying read.
        </Paragraph>
        <AdditionalParagraph>
          <strong>Dork Diaries Series</strong> • Rachel Renée Russell • 4.2–5.4,
          MG • <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph>
          <strong>I Survived Series</strong> • Lauren Tarshis • 3.8–5.1, MG •{" "}
          <strong>NF</strong> • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
      <div>
        <Paragraph>
          Authentic Spanish literature allows students to see their own lives
          and cultures reflected, while also learning about stories, characters,
          and traditions different from their own.
        </Paragraph>
        <AdditionalParagraph lang="es">
          <strong>Srta. Quinces</strong> • Kat Fajardo • 2.5, MG •{" "}
          <strong>SP</strong>
        </AdditionalParagraph>
        <AdditionalParagraph lang="es">
          <strong>El canto de las palomas</strong> • Juan Felipe Herrera • 5.2,
          LG • <strong>SP</strong>
        </AdditionalParagraph>
      </div>
    </ExploreLayout1>
  );
};

export default Grade6EnUS;
